import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
`;

const CenteredModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
`;

const ForeignAccountConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  action
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  action: string;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <CenteredModalContainer>
        <StyledBox>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="h5" color="black">
              Confirm {`${action}`}
            </Typography>
            <Typography variant="body1" color="black" mt={2}>
              Are you sure you want to <strong>{`${action}`}</strong> for this
              creator?
            </Typography>
            <Typography variant="body1" color="red" mt={2}>
              Note: Before giving a creator foreign account status, ensure they
              have already set up a Stripe account that is also foreign.
            </Typography>
            <Box mt={2}>
              <Button variant="contained" color="success" onClick={onConfirm}>
                Yes
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{ marginLeft: 2 }}
              >
                No
              </Button>
            </Box>
          </Stack>
        </StyledBox>
      </CenteredModalContainer>
    </Modal>
  );
};

export default ForeignAccountConfirmationModal;
