import { Grid, ToolbarProps } from '@mui/material';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';

export const AdminEdit = (props: ToolbarProps) => (
  <Edit>
    <SimpleForm
      toolbar={
        <Toolbar {...props}>
          <SaveButton />
        </Toolbar>
      }
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6}>
          <TextInput source="id" fullWidth disabled />
        </Grid>
        <Grid item md={6}>
          <TextInput source="email" fullWidth disabled />
        </Grid>
        <Grid item md={6}>
          <TextInput source="createdAt" fullWidth disabled />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);
