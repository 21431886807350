import { TipType } from '@/gql/generated/graphql';
import { getUsersActions } from '@/server-actions';
import { Input } from '@fanfix/design-system';
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';

interface Props {
  userId: string;
}

export const PaymentList: React.FC<Props> = ({ userId }) => {
  const { getFanPayments } = getUsersActions();
  const [payments, setPayments] = useState<Data[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [tip, setTip] = useState<TipType | undefined>();
  const [relatedEntityId, setRelatedEntity] = useState('');
  const [creatorId, setCreatorId] = useState('');

  const flattenPayments = (response: any) => {
    return response.payments.map((payment: any) => ({
      amountInCents: payment.amountInCents,
      applicationFeeAmountInCents: payment.applicationFeeAmountInCents,
      createdAt: payment.createdAt,
      creatorId: payment.creatorId,
      // deletedAt: payment.deletedAt,
      fanId: payment.fanId,
      id: payment.id,
      paymentProcessor: payment.paymentProcessor,
      paymentProcessorTransactionId: payment.paymentProcessorTransactionId,
      processingFeeAmountInCents: payment.processingFeeAmountInCents,
      relatedEntityId: payment.relatedEntityId,
      salesTaxAmountInCents: payment.salesTaxAmountInCents,
      salesTaxBillingLocation_countryCode:
        payment.salesTaxBillingLocation?.countryCode,
      salesTaxBillingLocation_region: payment.salesTaxBillingLocation?.region,
      salesTaxBillingLocation_zip: payment.salesTaxBillingLocation?.zip,
      status: payment.status,
      tipType: payment.tipType,
      updatedAt: payment.updatedAt
    }));
  };

  const retrievePayments = async () => {
    const { adminGetPayments } = await getFanPayments({
      userId: userId,
      input: {
        pagination: { limit: rowsPerPage, offset: page * rowsPerPage },
        filter: {
          tipType: tip,
          relatedEntityId: relatedEntityId,
          creatorId: creatorId
        }
      }
    });

    const flat = flattenPayments(adminGetPayments);
    setTotalCount(adminGetPayments.totalCount);
    setPayments(flat);
  };

  useEffect(() => {
    retrievePayments();
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  const handleTipChange = (event: SelectChangeEvent) => {
    setTip(event.target.value as TipType);
  };

  const handleRelatedEntityIdChange = (val: string) => {
    setRelatedEntity(val);
  };

  const handleCreatorIdChange = (val: string) => {
    setCreatorId(val);
  };

  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Paper
        sx={{
          overflowX: 'auto',
          maxWidth: 'lg'
        }}
      >
        <Stack direction="row" spacing={1} ml={1} mt={1} width={'50%'}>
          <Input
            id={'filter-creatorId'}
            onChange={handleCreatorIdChange}
            value={creatorId}
            label="Creator ID"
          />
          <Input
            id={'relatedEntityId'}
            onChange={handleRelatedEntityIdChange}
            value={relatedEntityId}
            label="Related Entity ID"
          />
          <Select
            id="tip-select"
            value={tip}
            label="Tip Type"
            onChange={handleTipChange}
          >
            {TipTypes.map((tipType: string, index: number) => (
              <MenuItem key={`tip-select-item-${index}`} value={tipType}>
                {tipType}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              retrievePayments();
            }}
          >
            Filter
          </Button>
        </Stack>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {columns.map((column: Column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const TipTypes: Array<TipType> = [
  TipType.CreatorTip,
  TipType.Livestream,
  TipType.LivestreamComment,
  TipType.MediaUnlock,
  TipType.Message,
  TipType.Post,
  TipType.PostUnlock,
  TipType.SubscriptionCharge
];

interface Data {
  amountInCents: number;
  applicationFeeAmountInCents: number;
  createdAt: string;
  creatorId: string;
  deletedAt: string | null;
  fanId: string;
  id: string;
  paymentProcessor: string;
  paymentProcessorTransactionId: string;
  processingFeeAmountInCents: number;
  relatedEntityId: string;
  salesTaxAmountInCents: number;
  salesTaxBillingLocation_countryCode: string;
  salesTaxBillingLocation_region: string;
  salesTaxBillingLocation_zip: string;
  status: string;
  tipType: string;
  updatedAt: string;
  [key: string]: any; // Add index signature
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: 'amountInCents',
    label: 'Amount (Cents)',
    minWidth: 100,
    align: 'right'
  },
  {
    id: 'applicationFeeAmountInCents',
    label: 'Application Fee (Cents)',
    minWidth: 150,
    align: 'right'
  },
  { id: 'createdAt', label: 'Created At', minWidth: 150 },
  { id: 'creatorId', label: 'Creator ID', minWidth: 150 },
  // { id: "deletedAt", label: "Deleted At", minWidth: 150 },
  { id: 'fanId', label: 'Fan ID', minWidth: 150 },
  { id: 'id', label: 'Payment ID', minWidth: 150 },
  { id: 'paymentProcessor', label: 'Payment Processor', minWidth: 150 },
  {
    id: 'paymentProcessorTransactionId',
    label: 'Processor Transaction ID',
    minWidth: 150
  },
  {
    id: 'processingFeeAmountInCents',
    label: 'Processing Fee (Cents)',
    minWidth: 150,
    align: 'right'
  },
  { id: 'relatedEntityId', label: 'Related Entity ID', minWidth: 150 },
  {
    id: 'salesTaxAmountInCents',
    label: 'Sales Tax (Cents)',
    minWidth: 150,
    align: 'right'
  },
  {
    id: 'salesTaxBillingLocation',
    label: 'Sales Tax Location',
    minWidth: 200,
    format: (location: any) =>
      `${location.countryCode}, ${location.region}, ${location.zip}`
  },
  { id: 'status', label: 'Status', minWidth: 150 },
  { id: 'tipType', label: 'Tip Type', minWidth: 150 },
  { id: 'updatedAt', label: 'Updated At', minWidth: 150 }
];
