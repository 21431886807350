import { Box } from '@mui/material';
import styled from 'styled-components';

const CenteredModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
`;

const StyledBox = styled(Box)`
  width: 500px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
`;
export { CenteredModalContainer, StyledBox };
