import { getAdminLivestreamActions } from '@/server-actions';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  useEditContext,
  useNotify,
  useRefresh
} from 'react-admin';
import ConfirmationModal from '../creators/ConfirmationModal';

interface UpdateDetails {
  title: string;
  allowsTips: boolean;
  allowsComments: boolean;
}

export const LivestreamEditForm = () => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const { record } = useEditContext();
  const [updateDetails, setUpdateDetails] = useState<UpdateDetails>({
    title: record?.title ?? '',
    allowsTips: record?.allowsTips ?? true,
    allowsComments: record?.allowsComments ?? true
  });
  const { creatorId, id } = record;
  const { endLiveStream, updateLiveStream } = getAdminLivestreamActions();

  const refresh = useRefresh();
  const notify = useNotify();

  const handleConfirmEndStream = async () => {
    try {
      await endLiveStream({
        input: {
          creatorId,
          id
        }
      });
      refresh();
      notify('Livestream ended successfully', { type: 'info' });
    } catch (error) {
      notify('Error ending livestream', { type: 'error' });
    } finally {
      setOpenConfirmationModal(false);
    }
  };

  const updateLiveStreamData = async () => {
    try {
      await updateLiveStream({
        input: { ...updateDetails, creatorId, id }
      });
      refresh();
      notify('Livestream updated successfully', { type: 'info' });
    } catch (error) {
      notify('Error updating livestream', { type: 'error' });
    } finally {
      setOpenConfirmationModal(false);
    }
  };

  const handleCloseModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleChange = (
    name: keyof typeof updateDetails,
    value: string | boolean
  ) => {
    setUpdateDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <>
      <SimpleForm toolbar={<></>}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Creator ID:
            </Typography>
            <Link
              href={`/#/creators/${record.creatorId}`}
              color="primary"
              underline="hover"
            >
              {record.creatorId}
            </Link>
          </Grid>
          <Grid item md={6}>
            <TextInput source="id" label="Livestream ID" fullWidth disabled />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="title"
              label="Title"
              fullWidth
              name="title"
              value={updateDetails.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="startedAt"
              label="Started At"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <TextInput source="endedAt" label="Ended At" fullWidth disabled />
          </Grid>
          <Grid item md={6}>
            <TextInput source="roomId" label="Room ID" fullWidth disabled />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="sessionId"
              label="Session ID"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="audienceType"
              label="Audience Type"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <BooleanInput
              source="allowsComments"
              label="Allows Comments"
              fullWidth
              name="allowsComments"
              value={updateDetails.allowsComments}
              onChange={() =>
                handleChange('allowsComments', !updateDetails.allowsComments)
              }
            />
          </Grid>
          <Grid item md={6}>
            <BooleanInput
              source="allowsTips"
              label="Allows Tips"
              fullWidth
              name="allowsTips"
              value={updateDetails.allowsTips}
              onChange={() =>
                handleChange('allowsTips', !updateDetails.allowsTips)
              }
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="minimumCommentTipAmountInCents"
              label="Min Comment Tip Amount (in cents)"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="totalTipAmountInCents"
              label="Total Tip Amount (in cents)"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="createdAt"
              label="Created At"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="updatedAt"
              label="Updated At"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={6}>
            <TextInput
              source="deletedAt"
              label="Deleted At"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            disabled={Boolean(record.endedAt)}
            variant="contained"
            color="error"
            sx={{ mx: 2, px: 4 }}
            onClick={() => {
              setActionType('end');
              setOpenConfirmationModal(true);
            }}
          >
            End
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mx: 2, px: 4 }}
            onClick={() => {
              setActionType('update');
              setOpenConfirmationModal(true);
            }}
          >
            Update
          </Button>
        </Box>
      </SimpleForm>

      {actionType === 'end' && (
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirmEndStream}
          action="End Livestream?"
        />
      )}
      {actionType === 'update' && (
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={handleCloseModal}
          onConfirm={updateLiveStreamData}
          action="Update Livestream?"
        />
      )}
    </>
  );
};
