import { Chip } from '@mui/material';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
  useRecordContext
} from 'react-admin';
import { AdvancedListActions } from '../shared/AdvancedFilterForm';

const QuickFilter = ({
  label
}: {
  label: string;
  source?: string;
  defaultValue?: string;
}) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const UserPagination = () => (
  <div style={{ width: '100%', display: 'flex' }}>
    <Pagination rowsPerPageOptions={[10, 25, 50]} />
  </div>
);

const CreatorProfileAccepted = ({ source }: { source: string }) => {
  const record = useRecordContext();
  record.hasCreatorProfile = Boolean(record[source]?.acceptedAt);
  return (
    <BooleanField
      record={record}
      source={'hasCreatorProfile'}
      label="Creator Accepted"
      valueLabelTrue="Accepted"
      data-testid={'creator-prfoile-accepted-ad'}
    />
  );
};

export const UserList = () => (
  <div
    style={{
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      maxWidth: '85%'
    }}
  >
    <List
      data-testid={'user-list-ad'}
      pagination={<UserPagination />}
      actions={<AdvancedListActions />}
    >
      <Datagrid
        data-testid={'user-datagrid-ad'}
        rowClick="edit"
        bulkActionButtons={false}
      >
        <TextField data-testid={'user-userId-column-ad'} source="id" />
        <TextField
          data-testid={'user-email-column-ad'}
          label="Email"
          source="email"
        />
        <TextField
          data-testid={'user-username-column-ad'}
          label="Username"
          source="userProfile.username"
        />
        <CreatorProfileAccepted source="creatorProfile" />
        <TextField
          data-testid={'user-firstName-column-ad'}
          label="First Name"
          source="userProfile.firstName"
        />
        <TextField
          data-testid={'user-lastName-column-ad'}
          label="Last Name"
          source="userProfile.lastName"
        />
        <DateField label="Deleted At" source="deletedAt" />
        <DateField label="Last Login At" source="lastLoginAt" />
        <DateField label="Last Seen At" source="lastSeenAt" />
        <DateField
          data-testid={'user-created-at-column-ad'}
          label="Created At"
          source="createdAt"
        />
      </Datagrid>
    </List>
  </div>
);
